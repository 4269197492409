import React from "react";
import Auth from "./auth";
import Products from "./products";

const Admin = () => {
  const val = sessionStorage.getItem("LoggedIn");
  return val && val === "true" ? <Products /> : <Auth />;
};

export default Admin;
