import React from "react";
import Clients from "../parts/Clients";
import sandeels1 from "../images/sandeels1.jpg";
import sandeels2 from "../images/sandeels2.jpg";
import mackrel1 from "../images/mackrel1.jpg";
import mackrel2 from "../images/mackrel2.jpg";
import shrimp1 from "../images/shrimp1.jpg";
import cray1 from "../images/cray1.jpg";
import cray2 from "../images/cray2.jpg";
import superba1 from "../images/superba1.jpg";
import superba2 from "../images/superba2.jpg";

const Supplies = () => {
  return (
    <div className="row">
      <div className="two-thirds">
        <h2>Sustainable Supplies</h2>
        <div className="row">
          <div className="full-width">
            <p>
              <b>
                Marine Nutrition is a company dedicated to supplying the finest
                quality frozen feedstuffs to public Aquariums and Zoos.
              </b>
            </p>
            <p>
              Our philosophy is to only source products where available from
              sustainable and reponsible fisheries - and to combine this with
              competitive pricing and first class service.
            </p>
            <p>
              Over the years we have built up a network of bait collectors and
              small scale inshore fishermen who operate in a sustainable manner
              and are able to supply us with the quality produce we need. Allied
              to this small-scale network is our ability to import in quantity
              such items as Superba Krill and Californian Squid which helps to
              keep our prices very competitive.
            </p>
            <p>
              Based in Evesham, Marine Nutrition has its own 150-pallet
              coldstore plus blast freezing operations.
            </p>
          </div>
        </div>

        <h3>Sandeels</h3>
        <p>
          All our inshore sandeels are caught by one fisherman operating an 18ft
          dory from a small harbour in Cornwall. This fishery has been in
          existence for over forty years and is incredibly low-impact and
          sustainable.
        </p>
        <div className="row">
          <div className="one-half">
            <img src={sandeels1} alt="Sand Eels" />
          </div>
          <div className="one-half">
            <img src={sandeels2} alt="Sand Eels" />
          </div>
        </div>

        <h3>Mackerel</h3>
        <p>
          Wherever possible, we only use Cornish hand-line caught mackerel
          landed by small 'cove' boats. This low impact fishery has no bycatch,
          provides valuable local jobs and is one of the most sustainable
          fisheries in the UK.
        </p>
        <div className="row">
          <div className="one-half">
            <img src={mackrel1} alt="Mackerel" />
          </div>
          <div className="one-half">
            <img src={mackrel2} alt="Mackerel" />
          </div>
        </div>
        <p>
          All our mackerel are dip-glazed after blast freezing to prevent
          freezerburn and dehydration.
        </p>

        <h3>Shrimps</h3>
        <p>
          Our shrimps are supplied by a team of bait collectors who use push
          nets to work the tide's edge and harvest the shrimps. A very low
          impact small scale fishery.
        </p>
        <p>
          <img src={shrimp1} alt="Shrimp" />
        </p>

        <h3>Crayfish</h3>
        <p>
          A superb otter food. We trap our signal crayfish on our local water
          and, again, immediately blast-freeze to ensure optimum quality.
        </p>
        <div className="row">
          <div className="one-half">
            <img src={cray1} alt="Crayfish" />
          </div>
          <div className="one-half">
            <img src={cray2} alt="Crayfish" />
          </div>
        </div>

        <h3>Superba and Pacifica</h3>
        <p>
          We buy our superba and pacifica in large frozen-at-sea blocks - this
          ensures they have very high nutritional value and excellent texture.
          However, in this format, they are very awkward to use so, while
          frozen, we bandsaw our blocks into approx. 30mm thick slices weighing
          approx 500g, which are then vacuum-packed - giving you the best
          product in the best format.
        </p>
        <div className="row">
          <div className="one-half">
            <img src={superba1} alt="Superba and Pacifica" />
          </div>
          <div className="one-half">
            <img src={superba2} alt="Superba and Pacifica" />
          </div>
        </div>
      </div>
      <div className="one-third">
        <Clients />
      </div>
    </div>
  );
};

export default Supplies;
