import React, { useState } from "react";
import Clients from "../parts/Clients";

const ContactUs = () => {
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Sending, setSending] = useState(false);
  const [Sent, setSent] = useState(false);

  const doSubmit = async () => {
    try {
      if (Name === "" || Phone === "" || Email === "" || Message === "") {
        window.alert(
          "You must complete all fields before you can send a message"
        );
        return false;
      }
      setSending(true);
      const body = {
        Name,
        Phone,
        Email,
        Message,
      };
      await fetch(
        "https://marinenutritionsitefunctions.azurewebsites.net/api/emailformcapture",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      setSent(true);
    } catch (error) {
      setSent(false);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="row">
      <div className="two-thirds">
        <h2>Contact Us</h2>

        <p>Thanks for your interest in Marine Nutrition</p>
        <p>
          You can send us a message directly using the form below. Alternatively
          you can call us on 0808 198 2879, or write to us or visit at the
          address below. We'll get back to you as soon as possible.
        </p>
        <p>
          Please note: Our opening times are Monday to Friday 8.30am til 5pm and
          Saturdays 8.30am til 11.30am
        </p>

        <div className="form-group">
          <label>Your name:</label>
          <input
            type="text"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Phone:</label>
          <input
            type="text"
            value={Phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="text"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea
            rows={10}
            cols={50}
            value={Message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        {Sending && <p>Sending message...</p>}
        {Sent && (
          <p>
            <b>Message sent - thank you.</b>
          </p>
        )}
        {!Sending && !Sent && (
          <p>
            <button onClick={doSubmit}>Send Message</button>
          </p>
        )}
      </div>
      <div className="one-third">
        <Clients />
      </div>
    </div>
  );
};

export default ContactUs;
