import React from "react";

const Menu = () => {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="Menu">
      <p>
        <span onClick={ScrollToTop}>Top of page</span>
      </p>
      <p>
        <a href="/">Home</a>
      </p>
      <p>
        <a href="/supplies">Sustainable Supplies</a>
      </p>
      <p>
        <a href="/UKProductList">Product &amp; Stock List</a>
      </p>
      <p>
        <a href="/HowToOrder">How to Order</a>
      </p>
      <p>
        <a href="/Delivery">Delivery</a>
      </p>
      <p>
        <a href="/FAQ">FAQ</a>
      </p>
      <p>
        <a href="/contact">Contact Us</a>
      </p>
    </div>
  );
};

export default Menu;
