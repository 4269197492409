import React, { useState } from "react";

const NewProduct = ({ onUpdate }) => {
  const [Category, setCategory] = useState("");
  const [Code, setCode] = useState("");
  const [Description, setDescription] = useState("");
  const [Price, setPrice] = useState(0);
  const [InStock, setInStock] = useState(false);

  const doSave = async () => {
    try {
      const obj = {
        UniqueId: null,
        Category,
        Code,
        Description,
        Price,
        InStock,
      };
      await fetch(
        "https://marinenutritionsitefunctions.azurewebsites.net/api/ProductDataChange",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj),
        }
      );
      setCategory("");
      setCode("");
      setDescription("");
      setPrice("");
      setInStock(false);
      onUpdate();
    } catch (error) {
      window.alert("Error saving product");
    }
  };

  return (
    <>
      <h4>New Product</h4>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <input
            placeholder="Category"
            type="text"
            style={{ width: "90%" }}
            value={Category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        <div>
          <input
            placeholder="Code"
            type="text"
            style={{ width: "90%" }}
            value={Code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div>
          <input
            placeholder="Description"
            type="text"
            style={{ width: "90%" }}
            value={Description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div>
          &pound;
          <input
            placeholder="Price"
            type="number"
            style={{ width: "80%" }}
            value={Price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div>
          <span>In stock?</span>
          <input
            type="checkbox"
            checked={InStock}
            onChange={(e) => setInStock(e.target.checked)}
          />
        </div>
        <div>
          <button onClick={doSave}>Save</button>
        </div>
      </div>
    </>
  );
};

export default NewProduct;
