import React from "react";
import Menu from "./Menu";

const Footer = () => {
  return (
    <footer>
      <Menu />
    </footer>
  );
};

export default Footer;
