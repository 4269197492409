import React, { useState } from "react";

const Auth = () => {
  const [Password, setPassword] = useState("");

  const doLogin = async () => {
    if (window.btoa(Password) === "YmFkc2V5Mg==") {
      sessionStorage.setItem("LoggedIn", "true");
      window.location.reload();
    } else {
      window.alert("Invalid password");
    }
  };
  return (
    <>
      <h3>Authorisation</h3>
      <p>
        <b>Password needed:</b>{" "}
        <input
          type="password"
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
        />{" "}
        <button onClick={doLogin}>Login</button>
      </p>
    </>
  );
};

export default Auth;
