import React, { useState } from "react";

const List = ({ onUpdate, Data }) => {
  return (
    <table style={{ width: "90%" }}>
      <thead>
        <tr>
          <th style={{ width: "10%" }}>Category</th>
          <th style={{ width: "10%" }}>Code</th>
          <th style={{ width: "50%" }}>Description</th>
          <th style={{ width: "10%" }}>Price</th>
          <th style={{ width: "5%" }}>Stock</th>
          <th style={{ width: "15%" }}></th>
        </tr>
      </thead>
      <tbody>
        {Data.sort((a, b) => {
          if (a.Category === b.Category) {
            if (a.Category < b.Code) {
              return -1;
            } else {
              return 1;
            }
          } else {
            if (a.Category < b.Code) {
              return 0;
            } else {
              return 1;
            }
          }
        }).map((product) => (
          <Item Data={product} onDelete={onUpdate} key={product.UniqueId} />
        ))}
      </tbody>
    </table>
  );
};

export default List;

const Item = ({ Data, onDelete }) => {
  const [Category, setCategory] = useState(Data?.Category);
  const [Code, setCode] = useState(Data?.Code);
  const [Description, setDescription] = useState(Data?.Description);
  const [Price, setPrice] = useState(Data?.Price.toFixed(2));
  const [InStock, setInStock] = useState(Data?.InStock || false);

  const doUpdate = async () => {
    try {
      const obj = {
        UniqueId: Data?.UniqueId,
        Category,
        Code,
        Description,
        Price,
        InStock,
      };
      await fetch(
        "https://marinenutritionsitefunctions.azurewebsites.net/api/ProductDataChange",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj),
        }
      );
      window.alert("Save complete");
    } catch (error) {
      window.alert("Error saving update");
    }
  };

  const doDelete = async () => {
    if (window.confirm("Are you sure?")) {
      const obj = { UniqueId: Data?.UniqueId };
      await fetch(
        "https://marinenutritionsitefunctions.azurewebsites.net/api/ProductDelete",
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj),
        }
      );
      onDelete();
    }
  };

  return (
    <tr key={Data.UniqueId}>
      <td>
        <input
          type="text"
          style={{ width: "90%" }}
          value={Category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          style={{ width: "90%" }}
          value={Code}
          onChange={(e) => setCode(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          style={{ width: "90%" }}
          value={Description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </td>
      <td>
        &pound;
        <input
          type="number"
          style={{ width: "80%" }}
          value={Price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={InStock}
          onChange={(e) => setInStock(e.target.checked)}
        />
      </td>
      <td>
        <button
          style={{ background: "red", marginRight: "0.5rem" }}
          onClick={doDelete}
        >
          Delete
        </button>
        <button onClick={doUpdate}>Save</button>
      </td>
    </tr>
  );
};
