import React, { useEffect, useState } from "react";
import Clients from "../parts/Clients";

const UKProductList = () => {
  const [Data, setData] = useState([]);
  const [Sections, setSections] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const req = await fetch(
          "https://marinenutritionsitefunctions.azurewebsites.net/api/productlist"
        );
        const res = await req.json();
        setData(res.data);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (Data.length) {
      const uniques = new Set();
      for (const itm of Data) uniques.add(itm.Category);
      setSections([...uniques].sort((a, b) => (a < b ? -1 : 1)));
    }
  }, [Data]);

  return (
    <div className="row">
      <div className="two-thirds">
        <h2>Product / Stock List</h2>

        {Sections.map((itm, idx) => (
          <table>
            <thead>
              <tr>
                <th colSpan={3}>
                  <h3>{itm}</h3>
                </th>
              </tr>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {Data.filter((c) => c.Category === itm)
                .sort((a, b) => (a.Code < b.Code ? -1 : 1))
                .map((product, idx) => {
                  const className =
                    product.InStock && product.Price > 0
                      ? "stripeOnHover"
                      : "disabledProduct";
                  return (
                    <tr className={className}>
                      <td>{product.Code}</td>
                      <td>
                        {product.Description}
                        {!product.InStock && " (Out of stock)"}
                      </td>
                      <td>&pound;{product.Price.toFixed(2)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ))}
      </div>
      <div className="one-third">
        <Clients />
      </div>
    </div>
  );
};

export default UKProductList;
