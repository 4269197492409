import React from "react";
import msc from "../images/MSC_eco_logo_medium.png";
import Clients from "../parts/Clients";

const Home = () => {
  return (
    <>
      <div className="row">
        <div className="one-third home-header-square">
          <h4>
            Marine Nutrition supplies the finest quality sustainably sourced
            frozen foodstuffs to Public Zoos and Aquariums throughout Europe.
          </h4>
          <p>
            Contact us to receive our latest price list which can be supplied by
            e-mail as an excel spreadsheet. If you would like to chat about our
            products and our sourcing policies please call us.
          </p>
        </div>
        <div className="two-thirds home-header-image">
          <p>Suppliers of all your frozen zoo and aquarium products</p>
          <p>
            Our philosophy is to source products from sustainable and reponsible
            fisheries wherever possible.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="one-third">
          <h2>Sustainable Supplies</h2>
          <p>
            Our philosophy is to only source products from sustainable and
            reponsible fisheries - and to combine this with competitive pricing
            and first class service.
          </p>
          <p>
            <a href="/supplies">Read more...</a>
          </p>

          <h2>Affiliates</h2>
          <p>
            <b>Marine Biological Association</b>
            <br />
            The Marine Biological Association (MBA) was founded in 1884 and is
            involved in wide ranging marine research projects.Its aim is to
            provide a clear independant voice to government, marine stakeholders
            and the wider public. The MBA are passionate about their quest to
            better understand the marine enviroment for the benefit of all
            society as well as the marine biological community. Marine Nutrition
            is a supporter of the MBA registered charity no 226063, please{" "}
            <a href="http://www.mba.ac.uk" target="_new" rel="nofollow">
              visit their website
            </a>{" "}
            and support the work they do.
          </p>
        </div>
        <div className="one-third">
          <h2>Product Lists</h2>
          <p>
            Our aim at Marine Nutrition is to supply quality frozen food that
            offers excellent value. Our pricing is structured to allow all our
            customers a fair deal whether it's a large Zoo ordering three
            pallets of sprats from the Super Discount section or a small visitor
            attraction wanting 3 kg of Mysis and 10kg of bloodworm.
          </p>

          <h2>MSC</h2>
          <img src={msc} className="float-right" alt="MSC Branding" />
          <p>
            We all know that the world's oceans are under pressure like never
            before, whether it's from mineral exploitation, pollution or over
            fishing.
          </p>
          <p>
            At Marine Nutrition we have always felt that our industry has a
            strong moral obligation to set an example of best practice in
            sourcing marine products from sustainable fisheries.
          </p>
          <p>
            While we have always tried our best to source from sustainable
            fisheries we felt the time was right to use a more formal structure
            to achieve this, to that end we set out to be the first specialist
            animal feed supplier in the UK to gain MSC certification.
          </p>
        </div>
        <div className="one-third">
          <Clients />
        </div>
      </div>
    </>
  );
};

export default Home;
