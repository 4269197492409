import React from "react";
import Clients from "../parts/Clients";

const FAQ = () => {
  return (
    <div className="row">
      <div className="two-thirds">
        <h2>Frequently Asked Questions</h2>
        <h3>WHAT IS YOUR DELIVERY CHARGE?</h3>
        <p>
          Our standard delivery charge is £18.00 + VAT for most of mainland UK. Please be aware this charge may not
          apply to customers customers outside the UK.
        </p>
        <h3>WHAT ARE MY DELIVERY OPTIONS?</h3>
        <p>
          England, Wales and Southern Scotland - We provide delivery on our own fleet of freezer trucks on our scheduled
          runs. For small orders or an emergency supply, we do offer an overnight courier service available on a Monday,
          Tuesday and Wednesday for next day delivery. Scottish Highlands - From our storage facility in Inverness, we
          can provide delivery via a carrier company based in the Scottish Highlands. Delivery charges vary depending on
          location. Northern Ireland - Please contact us. For deliveries outside of the above areas, please contact us.
        </p>
        <h3>WHEN ARE YOU NEXT IN MY AREA?</h3>
        <p>
          Most areas are covered on a fortnightly basis. Due to the schedule rotation of our deliveries, the best way to
          find out when we are next in you area is to call us on 01386 830089 or visit our Facebook page via our link
          and all the latest deliveries will be posted on our wall. You may also like to use our online delivery
          schedule facility. Customers are very welcome to collect from our depot near Evesham in Worcestershire.
        </p>
        <h3>I HAVE PLACED AN ORDER, WHEN CAN I EXPECT MY DELIVERY?</h3>
        <p>
          Once your order had been processed by a member of our team, we will contact you to confirm details of our next
          scheduled delivery in your area.
        </p>
        <h3>AN ITEM I HAVE ORDERED BEFORE DOES NOT APPEAR TO BE ON THE WEBSITE ANY MORE. WHY IS THIS?</h3>
        <p>
          You may not be able to see a previously ordered item due to not currently being in stock. Please contact us if
          you require specific item as we may be able to offer an alternative or inform you of when we expect that item
          to be available again.
        </p>
        <h3>WHAT IS YOUR MINIMUM ORDER?</h3>
        <p>
          There is no real minimum order for delivery on our own vehicles however, this would be assessed when planning
          the route. Customers will be notified accordingly. There is also limit as to what is able to fit in one
          parcel. This can be increased but you will incur a surcharge. There is no real minimum order for delivery on
          our own vehicles providing the logistics are adequate. See our delivery page for details.
        </p>
        <h3>DO YOU SUPPLY TO TRADE/PUBLIC?</h3>
        <p>Our customer base ranges from private individuals right up to some of the largest Zoos in the country.</p>
        <h3>HOW DO I PAY FOR MY ORDER?</h3>
        <p>
          We encourage all of our customer to pay prior to delivery via BACS or Card. We accept most types of major
          debit/credit cards. We do not accept American Express. We do not accept cheques. If you are a new customer,
          your first order must be pre-paid. However, if you are a returning customer we do offer the facility for our
          drivers to collect cash on delivery. If a driver is not provided with a cash payment upon delivery, the goods
          will not be left.
        </p>
        <h3>REFUNDS</h3>
        <p>
          If in the unfortunate event that you need to return goods adhering to the above points, a member of the team
          will then inspect the goods upon their arrival. Once the items have been assessed you will be contacted to
          inform you of the decision. If a refund is agreed, the amount to be refunded will be returned to either a) via
          refund to the card the initial payment was made on or b) a credit may be applied to your account for your next
          order. Please be aware due to the nature of our goods, no refund will be given without prior inspection of any
          damaged/faulty goods
        </p>
        <h3>CANCELLATIONS</h3>
        <p>
          Should you wish to cancel your order, please <a href="/contact">contact us</a> as soon as possible. We
          appreciate circumstances beyond your control may instigate this and will do our very best to assist in such
          situations. However if a courier delivery method is chosen for your order, then goods that have already been
          dispatched will not be unable to be cancelled and any refund due will be at the discretion of Marine Nutrition
          subject to the return of the goods.
        </p>
        <h3>NOT FOUND YOUR ANSWER?</h3>
        <p>
          If you wish to ask anything further, please use the <a href="/contact">Contact Us page</a> to send us your
          enquiry
        </p>
      </div>
      <div className="one-third">
        <Clients />
      </div>
    </div>
  );
};

export default FAQ;
