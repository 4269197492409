import React from "react";
import logo from "../images/marine_logo.png";
import biaza from "../images/biaza_header.png";
import eaza from "../images/eaza_header.png";
import msc from "../images/MSC-header.png";
import Menu from "./Menu";

const Header = () => {
  return (
    <header>
      <div className="header">
        <img src={logo} alt="Marine Nutrition" />
        <img src={msc} alt="MSC Registration" />
        <img src={biaza} alt="BIAZA Member" />
        <img src={eaza} alt="EAZA Member" />
        <div className="contact-info">
          <p>sustaining life</p>
          <p>
            <b>Freephone:</b>
            <br />
            0808 198 2879
          </p>
          <p>
            <b>Email:</b>
            <br />
            <a href="mailto:info@marinenutrition.com">
              info@marinenutrition.com
            </a>
          </p>
          <p>
            <b>Address:</b>
            <br />
            Old Station Yard, Birmingham Road, Blackminster, Worcestershire,
            WR11 7TD
          </p>
        </div>
      </div>
      <Menu />
    </header>
  );
};

export default Header;
