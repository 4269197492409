import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import List from "./list";
import NewProduct from "./newproduct";

const Products = () => {
  const [Data, setData] = useState([]);

  const RefreshList = useCallback(() => {
    (async () => {
      try {
        const req = await fetch(
          "https://marinenutritionsitefunctions.azurewebsites.net/api/productlist"
        );
        const res = await req.json();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    RefreshList();
  }, [RefreshList]);

  const nav = useNavigate();

  const val = sessionStorage.getItem("LoggedIn");
  if (!val || (val && val !== "true")) nav("/admin");

  const doLogout = () => {
    sessionStorage.removeItem("LoggedIn");
    window.location.reload();
  };
  return (
    <>
      <h3>Product Admin</h3>
      <p>
        <button onClick={doLogout}>Admin Log Out</button>
      </p>

      <NewProduct onUpdate={RefreshList} />
      <br />
      <br />
      <List Data={Data} onUpdate={RefreshList} />
    </>
  );
};

export default Products;
