import React from "react";
import Clients from "../parts/Clients";

const HowToOrder = () => {
  return (
    <div className="row">
      <div className="two-thirds">
        <h2>How to order</h2>
        <ul>
          <li>Over the telephone on our free-phone 0808 198 2789</li>
          <li>
            Collection directly from our office at Old Station Yard, Birmingham Road, Blackminster, Evesham,
            Worcestershire, WR11 7TD.
          </li>
          <li>We are open Mon to Fri 08:30 - 5.00pm and Saturdays 08.30am - 11.30am.</li>
          <li>
            Bank Holiday hours, Christmas and New Year vary. Please contact us directly for this information or check
            our facebook page for updates.
          </li>
          <li>We have a number of different delivery options depending on location and order quantities.</li>
        </ul>

        <h4>Our Designated Freezer Trucks Delivery</h4>
        <p>
          We offer delivery using our own fleet of trucks and vans on a rotating delivery schedule. We deliver in most
          areas of the UK every 2 weeks with Southern Scotland being covered once every 4 weeks. See our delivery route
          map page for more information.
        </p>
        <p>
          To qualify for free delivery, order over £500.00 worth of goods at discount prices. We have many customers who
          join together with friends or colleagues and have a delivery to one address to share costs or meet the free
          delivery requirements. Our standard delivery charge is £18.00 + VAT.
        </p>
        <p>All orders are subject to stock availability and price changes.</p>
        <p>
          In extreme cases, adverse weather conditions may cause deliveries to be delayed. Please also be aware that
          addresses in remote areas may not always be accessible to some of our bigger vehicles and so your co-operation
          in assisting our drivers with your delivery is always appreciated. Our drivers will do their best to always
          contact you before you delivery should you request this. Please ensure we have up to date contact numbers if
          you would like this service.
        </p>

        <h4>Next Day Courier Service</h4>
        <p>
          We offer a next day courier service predominantly for small orders. Although bigger orders can be sent via
          this method, it may not be the most cost effective way to receive your goods unless your order is urgent. Next
          day orders must be placed before 1pm. We offer this service on a Monday, Tuesday and Wednesday for next day
          delivery (where applicable). Orders placed after 1pm on a Wednesday will be dispatched on Monday for Tuesday
          Delivery. Some areas are not available on a next day service (Scottish Highlands, Isle of Wight, Northern
          Ireland).
        </p>
        <p>
          You do not have to be in to accept this delivery, though in the warmer months, we strongly advise all orders
          are placed into a freezer as soon as possible. We request that any delivery where there will be on-one to
          accept the goods, that a note is placed in a clear place to authorise the courier driver to leave where
          specified. If your property is difficult to find, please provide useful information to assist the courier in
          making your delivery.
        </p>
      </div>
      <div className="one-third">
        <Clients />
      </div>
    </div>
  );
};

export default HowToOrder;
