import React from "react";

const Clients = () => {
  return (
    <div className="client-list">
      <h3>Clients</h3>
      <p>Our customers already include</p>
      <ul>
        <li>Atlantis Aquarium, Legoland</li>
        <li>Battersea Park Zoo</li>
        <li>Belfast Zoo</li>
        <li>Blue Planet Aquarium</li>
        <li>Bristol Zoo</li>
        <li>Chessington Zoo</li>
        <li>Cotswold Wildlife Park</li>
        <li>Lake District Coastal Aquarium</li>
        <li>London Zoo and Aquarium</li>
        <li>Longleat Safari Park</li>
        <li>Manchester University</li>
        <li>National Marine Aquarium</li>
        <li>New Forest Wildlife Park</li>
        <li>Oceanario</li>
        <li>Paignton Zoo</li>
        <li>RSPCA</li>
        <li>RZSS Edinburgh Zoo</li>
        <li>Scottish Deer Centre</li>
        <li>Sea Life centres</li>
        <li>SSPCA Wildlife Rescue Centre</li>
        <li>St Andrews Aquarium</li>
        <li>The Big Cat Sanctuary</li>
        <li>The Deep, Hull</li>
        <li>Tropical Butterfly House</li>
        <li>Trust for Sustainable Living</li>
        <li>Welsh Mountain Zoo</li>
        <li>Woburn Safari Park</li>
        <li>Wildfowl and Wetlands Trust</li>
        <li>Yorkshire Wildlife Park</li>
        <li>ZSL Whipsnade Zoo</li>
      </ul>
    </div>
  );
};

export default Clients;
